.wrapper_license_header {
    display: grid;
    grid-template-columns: auto 162px;
    grid-gap: 4px;
    grid-column: 1/-1;
}

.search_input_wrapper {
    position: relative;
}

.search_input_wrapper input {
    background: #fff;
    width: 100%;
    border: 1px solid #EBECF0;
    padding: 8px 20px 8px 40px;
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #000;
}

.search_input_wrapper input::placeholder {
    color: #D1D1D1;
}

.search_input_wrapper img {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

#add_license {
    transition: 1s margin-top;
}

.add_license_active {
    margin-top: 32px !important;
}

.filters_wrapper {
    position: absolute;
    right: 0;
    top: 40px;
    padding: 17px;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 70px;
    background-color: white;
    z-index: 1000;
    border: 1px solid #EBECF0;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 540px;
}

.filters_wrapper > div {
    display: grid;
    grid-auto-rows: max-content;
    grid-gap: 9px;
}

.filters_wrapper > div > span {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #4E5D78;
}

.add_a_license {
    background: #0CCC92;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    display: grid;
    grid-template-columns: auto 1fr;
    font-size: 15px;
    line-height: 20px;
    align-content: center;
    cursor: pointer;
    color: #fff;
    grid-gap: 8px;
    padding: 0 18px;
}

.wrapper_input_activation_code {
    display: grid;
}

.license_error {
    font-size: 14px !important;
    line-height: 20px !important;
    color: red !important;
}

.wrapper_input_activation_code label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #4E5D78;
    cursor: pointer;
}

.wrapper_input_activation_code input {
    background: #FFFFFF;
    padding: 12px 8px;
    border: 1px solid #E2E4E9;
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #000
}

.global_popup .submit {
    margin: 20px 16px;
}

.search_value {
    margin-top: -16px;
    display: flex;
    max-width: 700px;
    flex-flow: wrap;
}

.search_value div {
    width: max-content;
    margin-right: 10px;
    background: #EAF6FD;
    border-radius: 11px;
    padding: 5px 12px;
    margin-top: 12px;
    align-items: center;
    display: flex;
}

.search_value span {
    font-weight: 600 !important;
    font-family: Inter;
    font-size: 12px;
    line-height: 14px;
    color: white;
    border-right: 1px solid rgba(255,255,255, .4);
    padding-right: 12px;
}

.search_value img {
    padding: 8px 12px 8px 16px;
    margin: -8px -12px -8px -8px;
    cursor: pointer;
}

.wrapper_all_license {
    grid-column: 1/-1;
    display: grid;
    justify-content: center;
    grid-gap: 24px;
    margin-top: 8px;
}

.license_command_button{
    color: #5C9BFF;
}

.licenses_header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.licenses_header .licenses_header_data {
    margin-left: auto;
}

.licenses_header .licenses_header_data span {
    font-size: 13px;
    line-height: 16px;
    color: #818181;
}

.wrapper_all_license .wrapper_every_license {
    background: #fff;
    border: 1px solid #EBECF0;
    box-sizing: border-box;
    border-radius: 5px;
    width: 871px;
    position: relative;
}

.wrapper_all_license .wrapper_every_license .every_license_header {
    padding: 16px;
}

.wrapper_all_license .wrapper_every_license .every_license_header .wrapper_all_block_values span,
.wrapper_all_license .wrapper_every_license .every_license_header .wrapper_all_block_titles span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    color: #4E5D78;
}

.wrapper_all_license .wrapper_every_license .every_license_header .wrapper_all_block_values span {
    color: #333333;
}

.wrapper_all_license .wrapper_every_license .every_license_header {
    overflow-x: hidden;
}

.blured_tags{
    background: linear-gradient(180deg, #FFFFFF 26.04%, rgba(255, 255, 255, 0) 100%);
    transform: rotate(90deg);
    position: absolute;
    right: 0;
    top: 43px;
    width: 24px;
    height: 24px;
}

.wrapper_all_license .wrapper_every_license .every_license_header::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
}

.wrapper_all_license .wrapper_every_license .every_license_header::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
    height: 4px;
}

.wrapper_all_license .wrapper_every_license .every_license_header::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #0A1F44;
}

.wrapper_all_license .wrapper_every_license .every_license_header .wrapper_all_block_values,
.wrapper_all_license .wrapper_every_license .every_license_header .wrapper_all_block_titles {
    display: grid;
    grid-template-columns: 100px 200px 120px 220px max-content;
    grid-gap: 12px;
}

.wrapper_all_license .wrapper_every_license .every_license_header .wrapper_all_block_values {
    margin-top: 8px;
}

.wrapper_all_license .wrapper_every_license .wrapper_all_block_values .tags_wrapper span {
    width: max-content;
    margin-left: 8px;
    background: #EAF6FD;
    border-radius: 11px;
    padding: 2px 12px;
    font-weight: 600 !important;
    font-family: Inter;
    font-size: 12px;
    line-height: 20px;
    color: #5C9BFF !important;
}

.wrapper_all_license .wrapper_every_license .wrapper_all_block_values .tags_wrapper span:first-child {
    margin-left: 0;
}

.wrapper_all_license .wrapper_every_license .wrapper_all_block_values .tags_wrapper span:last-child {
    margin-right: 20px;
}

.wrapper_all_license .wrapper_every_license .wrapper_license_body {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 4px;
    display: flex;
}

.wrapper_all_license .wrapper_every_license .wrapper_license_body div,
.api_buttons div{
    margin-left: 4px;
    cursor: pointer;
}

.license_text {
    font-size: 13px;
    line-height: 20px;
    color: #818181;
}

.edit_license_content {
    padding: 0 20px 0;
}

.edit_grant_wrapper {
    padding: 18px 0;
    border-bottom: 1px solid #ECECEC;
}

.edit_grant_wrapper > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4E5D78;
}

.edit_grant {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    margin-top: 18px;
}

.edit_grant > div span {
    font-size: 13px;
    line-height: 20px;
    color: #818181;
}

.editCheckbox {
    font-size: 13px;
    line-height: 20px;
    color: #4E5D78;
}

.editCheckbox img {
    top: 1px;
    left: 1px;
    width: 18px;
}

.edit_description {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}

.edit_description > span {
    font-size: 14px;
    line-height: 20px;
    color: #818181;

}

.edit_description > input {
    margin-top: 12px;
    background: #FFFFFF;
    border: 1px solid #E2E4E9;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 8px 12px;
    width: 100%;
}

.edit_tags_wrapper {
    margin-top: 20px;
}

.edit_tags_wrapper > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4E5D78;
}

.wrapper_all_license .wrapper_every_license .wrapper_license_body div:first-child,
.api_buttons div:first-child{
    margin-left: 0;
}

.no_licenses_wrapper {
    background: white;
    height: 300px;
    width: 875px;
    display: flex;
    align-items: center;
    border: 1px solid #EBECF0;
    justify-content: center;
    border-radius: 5px;
}

.wrapper_all_license .wrapper_every_license .wrapper_license_body div img {
    padding-right: 8px;
}

.react-tagsinput-tag{
    background-color: #EAF6FD !important;
    border-radius: 11px !important;
    border: none !important;
    color: #5C9BFF !important;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600 !important;
    font-family: Inter !important;
}

.react-tagsinput-tag:hover {
    background-color: #8ED6FF !important;
}

.react-tagsinput-tag a::before{
    margin-left: 12px;
    padding-left: 8px;
    border-left: 1px rgba(92, 169, 255, 0.5) solid;
    color: #5C9BFF !important;
    padding-top: -3px !important;
    font-size: 10px !important;
    content: url("../../assets/tag_delete.svg") !important;
}

.react-tagsinput{
    background: #FFFFFF !important;
    border: 1px solid #E2E4E9 !important;
    border-radius: 3px !important;
}

.react-tagsinput--focused {
    border: 1px solid #5C9BFF !important;
}

.delete_license_content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 22px;
    padding: 22px 18px;
}

.delete_license_content .warning_block {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 10px;
    padding: 18px;
    border: 1px solid #E2E4E9;
    box-sizing: border-box;
    border-radius: 5px;
    align-items: center;
}

.delete_license_content .warning_block img {
    height: 32px;
    width: 32px;
    animation: imgAppear 1s forwards;
}

@keyframes imgAppear {
    from {opacity: 0}
    to { opacity: 1}
}

.global_popup .installation_input_wrapper {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 16px;
}

.global_popup .installation_input {
    background: #333333;
    border-radius: 4px;
    height: 58px;
    padding: 7px 11px;
    display: flex;
    align-items: center;
}

.global_popup .installation_input span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    word-break: break-all;
    color: #4CFD01;
}

.delete_license_content .warning_block span {
    color: #FE3E3E;
    font-weight: 600;
}

.delete_license_content .delete_buttons {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 20px;
}
