.dashboard_wrapper {
    width: 860px;
    margin: auto;
    position: relative;
}

.test_data {
    display: flex;
    flex-direction: row;
    background: #FFFFFF;
    border: 1px solid #EBECF0;
    box-sizing: border-box;
    border-radius: 5px;
}


#activation_code:focus {
    background: #ECF3FF !important;
}


.test_data > img {
    padding: 12px 0 12px 19px;
}

.test_data > span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #818181;
    width: 404px;
    margin-left: 21px;
    padding: 12px 0;
}

.user_data_wrapper {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 200px);
    margin-top: 20px;
}

.user_data_wrapper > div {
    background: #FFFFFF;
    border: 1px solid #EBECF0;
    box-sizing: border-box;
    border-radius: 5px;
}

.user_data_plan {
    padding: 19px;
    display: grid;
    grid-gap: 11px;
}

.user_data_plan span:first-child {
    font-size: 24px;
    line-height: 23px;
    color: #4E5D78;
}

.gray_text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #818181;
}

.stats_text {
    font-size: 24px;
    line-height: 44px;
    color: #4E5D78;
}

.stats_text_bottom {
    font-size: 27px;
    line-height: 41px;
    color: #4E5D78;
}

.user_data_stats {
    padding: 17px 20px 8px;
}

.user_data_stats .data {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px
}

.user_data_stats > div:first-child {
    padding-bottom: 13px;
    border-bottom: 1px solid #E2E4E9;
}

.user_data_stats > div:last-child {
    padding-top: 18px;
}

.user_data_stats .used_data_grid {
    display: grid;
    grid-template-columns: max-content 1fr;
}

.chart_wrapper .chart_header > span {
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    padding-top: 8px;
}

.chart_wrapper {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #EBECF0;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 20px;
    padding: 12px 21px;
}

.chart_wrapper .chart_header {
    display: flex;
    flex-direction: row;
}

.chart_wrapper .chart_header .dropdowns_wrapper {
    margin-left: auto;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 9px;
}

.chart_wrapper .recharts-default-legend{
    display: none !important;
}

#hide {
    display: none;
}

.chart_wrapper .recharts-default-tooltip ul li span {
    color: unset;
}

.chart_wrapper .recharts-wrapper {
    margin-left: -50px;
}

.chart_wrapper1 .recharts-wrapper {
    background-image: url("../../assets/chartBackground2.svg");
    background-repeat: no-repeat;
    background-position-y: 102px;
    background-position-x: 82px;
}

.chart_wrapper2 .recharts-wrapper {
    background-image: url("../../assets/chartBackground.svg");
    background-repeat: no-repeat;
    background-position-y: 60px;
    background-position-x: 82px;
}

.pie_chart_wrapper {
    display: grid;
    grid-template-columns: 577px 261px;
    grid-gap: 22px;
    margin-top: 20px;
}

.pie_chart_wrapper > div {
    background: #FFFFFF;
    border: 1px solid #EBECF0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px 20px 20px;
}

.pie_chart_wrapper .recharts-wrapper:after {
    content: '100%';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 19px;
    line-height: 20px;
    color: #818181;
}

.pie_chart_wrapper .pie_chart_header {
    display: flex;
    flex-direction: row;
    margin-bottom: 34px;
}

.pie_chart_wrapper .pie_chart_header > div {
    margin-left: auto;
}

.pie_chart_block {
    position: relative;
}

.pie_chart_wrapper .pie_chart_content {
    padding-left: 40px;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.recharts-tooltip-wrapper {
    z-index: 1;
}

.recharts-default-tooltip{
    border: 1px solid #E2E4E9 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;
    border-radius: 3px !important;
}

.pie_chart_wrapper .pie_chart_data {
    display: grid;
    grid-template-columns: max-content;
    grid-gap: 8px;
    margin-left: 78px;
}

.pie_chart_wrapper .pie_chart_data > div {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 13px;

}

.pie_chart_wrapper .pie_chart_data > div > div:first-child{
    border-radius: 10px;
    width: 10px;
    height: 10px;
}

.pie_chart_wrapper .pie_chart_data > div > div:last-child {
    display: grid;
    grid-template-columns: 1fr 30px;
    width: calc(100% + 24px);
}

.pie_chart_wrapper .pie_chart_data > div > div:last-child span:last-child {
    justify-self: end;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #818181;
}

.pie_chart_wrapper .pie_chart_data > div > div:last-child span:first-child {
    font-size: 13px;
    line-height: 20px;
    color: #818181;
}

.pie_chart_header span {
    font-size: 18px;
    line-height: 20px;
    color: #333333;
}

.licenses_types_wrapper > span {
    font-size: 18px;
    line-height: 20px;
    color: #333333;
}

.licenses_types_wrapper .licenses_data {
    margin-top: 28px;
    grid-column-gap: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
}

.licenses_types_wrapper .licenses_data > div {
    display: grid;
    grid-auto-flow: column;
}

.licenses_types_wrapper .licenses_data > div span:first-child{
    font-size: 13px;
    line-height: 20px;
    color: #818181;
}

.licenses_types_wrapper .licenses_data > div span:last-child{
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #818181;
    justify-self: end;
}

.licenses_types_wrapper .licenses_footer {
    margin-top: 42px;
}

.licenses_types_wrapper .licenses_footer > span {
    font-size: 13px;
    line-height: 17px;
    color: #818181;
}

.log_wrapper {
    padding: 0;
}

.log_wrapper .log_header {
    padding: 12px 16px;
}

.log_wrapper .log_header span {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4E5D78;
}

.log_wrapper .log_content > div span {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #4E5D78;
}

.log_wrapper .log_content .log_content_header span {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #4E5D78;
}

.log_wrapper .log_content > div {
    display: grid;
    padding: 14px 16px;
    grid-template-columns: 140px 40px 300px 170px max-content;
    border-top: 1px solid rgba(0,0,0,.1);
    grid-gap: 24px
}

.log_wrapper .log_content > div .source {
    font-weight: 600;
    color: #818181;
}

.log_wrapper .log_content > div .status {
    font-weight: 600;
    padding-right: 0;
    border: none;
}
