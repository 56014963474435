@import "variables";
@import "global";

.block_header{
  padding: 12px 16px;
  border-bottom: 1px solid rgba(0,0,0,.1);
  transition: 0s !important;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4E5D78;
  }
}

.wrapper_profile_information {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;

  h1 {
    grid-column: 1/-1;
  }

  .login-form {
    h1 {
      font-size: 24px !important;
    }

    p {
      color: #4E5D78 !important;
    }

    input {
      border-color: #E2E4E9
    }
  }

  .save_profile_data {
    background: #0CCC92 !important;
    border: 2px solid #0CCC92 !important;
    width: 100%;
  }

  .save_profile_data:hover {
    background: #088072 !important;
    border: 2px solid #088072 !important;
  }

  button {
    color: white;
    font-weight: 600;
    border: 2px solid $main;
    border-radius: 4px;
    background-color: $main;

    &:hover {
      background-color: $main-hover;
      border-color: $main-hover;
      //border: 2px solid $main;
    }
  }

  &__input, button {
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 500;
    border-radius: 4px;
  }

  .login__wrapper{
    background: #FFFFFF;
    border: 1px solid #EBECF0;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: unset;

    &__header {
      display: grid;
      grid-template-columns: 1fr auto;
      margin-bottom: 28px;
    }

    &__header span:first-child{
      font-size: 29px;
      line-height: 23px;
    }

    &__body div{
      display: flex;
      flex-direction: column;
    }

    &__body {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px;

      &__button {
        background: #5C9BFF;
        border-radius: 4px;
        color: white;
        width: 100%;
        border: none;
      }

      &__title{
        font-size: 14px;
        line-height: 20px;
        color: #8A94A6;
      }

      &__description {
        font-size: 35px;
        line-height: 36px;
        color: #333333;

      }


      }
  }

  .delete_profile {
    &__wrapper {
      display: grid;
      grid-template-columns: 1fr;
      background: #FFFFFF;
      border: 1px solid #EBECF0;
      box-sizing: border-box;
      border-radius: 5px;
      width: 466px;
      padding: 42px;
      margin-top: 36px;
      grid-gap: 20px;
    }

    &__title{
      font-size: 24px;
      line-height: 32px;
      color: #0A1F44;
    }

    &__desription {
      font-size: 14px;
      line-height: 23px;
      color: #4E5D78;
    }

    &__button{
      background: #FE3E3E;
      border-radius: 4px;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #FFFFFF;
      text-align: center;
      padding: 12px 0;
      width: 220px;
      border: none;
    }

    &__button:hover {
      background: #CC0505;
    }

    &__cancel {
      background: #FFAB00 !important;
    }

    &__cancel:hover {
      background: #D99100 !important;
    }
  }

  .no__width{
    width: inherit;
    height: fit-content;
    margin-left: auto;
  }
}
