@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap&subset=cyrillic');

button:focus, label:focus, input:focus, textarea:focus {
  outline: none;
}

.react-tagsinput-input {
  margin-top: 0 !important;
  width: 170px !important;
  color: black !important;
}

.react-tagsinput-input::placeholder {
  color: #777;
}

.react-tagsinput-remove {
  padding: 5px 8px 5px 0px;
  margin-right: -8px;
}

.react-tagsinput{
  margin-top: 10px;
  padding-top: 5px !important;
  height: 35px !important;
  display: flex;
}

.react-tagsinput > span {
  display: flex;
  align-items: center;
  margin-right: -200px;
}

.react-tagsinput-tag {
  padding: 1px 9px !important;
}

* {
  outline: none !important;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  transition: $transition;
  color: $text-color-black
}

a:hover {
  text-decoration: none;
  color: $purple;
}

body {
  overflow-x: hidden;
}

h2 {
  font-size: 22px;
  font-weight: 600;
}

.loader_wrapper{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: white;
  z-index: 100001;
  display: grid;
  align-items: center;
  justify-content: center;
}

.mini_loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  &__error {
    position: absolute;
    display: grid;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-content: center;
    font-family: "Inter", sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-align: start;
    grid-auto-rows: max-content;
    grid-gap: 22px;
    max-width: 488px;
    margin: auto;
    color: rgba($color: $color-black-dark, $alpha: 0.8);




    &-big {
      font-size: 28px;
      line-height: 32px;
      color: #0A1F44;
    }

    &-small {
      font-size: 15px;
      line-height: 24px;
      color: #818181;
    }

    &-browser_wrapper > span {
      font-size: 15px;
      line-height: 24px;
      color: #818181;
    }

    &-browser_wrapper > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 26px;
      margin-top: 9px;
    }

    &-browser_wrapper > div > div{
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(2, max-content);
      align-items: center;
    }

    &-browser_wrapper img {
      margin-top: 0 !important;
    }

    &-browser_wrapper > div > div span {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }

    &-browser_wrapper a {
      font-size: 13px;
      line-height: 16px;
      color: #5C9BFF;
      z-index: 9999;
    }

      &-browser_wrapper > div > div > div {
      display: grid;
      grid-auto-rows: max-content;
      grid-gap: 4px;
    }

    img {
      margin-top: 9px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.1;
    }
  }
}

.sidenav {
  position: fixed;
  height: 100vh;
  width: $sidenav-width;
  padding-top: 14px;
  padding-bottom: 23px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $main-background;
  overflow-x: hidden;

  &__separator {
    height: 1px;
    width: calc(100%);
    background-color: rgba(255, 255, 255, 0.3);
    margin-bottom: 8px;
  }

  &-logo {
    padding-right: 24px;
    margin-bottom: 14px;
    margin-left: 18px;

    &__source {
      height: 32px;
      //width: 100%;
    }
  }

  &-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__item {
      //margin-bottom: 20px;

      &:last-of-type {
        margin: 0;
      }
    }

    &__link {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 18px;
      display: inline-flex;
      width: 100%;
      font-family: $font;
      line-height: 22px;
      color: white;
      transition: $transition;
      position: relative;

      &.active {
        opacity: 1;
        background-color: rgba(1, 255, 255, 0.15);

        .active_triangle {
          position: absolute;
          right: -3px;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 9px 10px 9px 0;
          border-color: transparent #f9f9f9 transparent transparent;

        }
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        opacity: 1;
        text-decoration: none;
        color: white;
      }
    }

    &__icon {
      width: 16px;
      height: 16px;
      margin: 2px 15px 0 0;
    }
  }

  &-exit {
    display: flex;
    align-items: center;
    font-family: $font;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: white;
    cursor: pointer;

    &__button {
      cursor: pointer;
      font-weight: bold;
    }

    &__icon {
      margin-right: 18px;
      margin-bottom: 4px;
    }

    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}

.margin-top-8 {
  margin-top: 8px;
}


.dashboardHeader {
  position: fixed;
  top: 0;
  left: $sidenav-width;
  right: 0;
  height: $header-height;
  display: flex;
  z-index: 10001;
  //align-content: flex-end;
  padding: 0;
  align-items: center;
  background: transparent;
  box-sizing: border-box;

  &__warning {
    display: grid;
    background: #68C38A;
    box-shadow: 1px 1px 17px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    padding: 8px 12px;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    margin-left: 24px;
    animation: 4s warning forwards;

    &__status {
      padding-right: 8px;
      border-right: rgba(53, 45, 45, 0.07) 1px solid;
    }

    span {
      padding-left: 8px;
      font-size: 15px;
      line-height: 17px;
      color: #FFFFFF;
      margin-right: 100px;
    }
  }

  &__profile {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  p {
    padding-bottom: 0;
    margin-bottom: 0;
    margin-right: 12px;
    color: $main-background;
  }

  &__initials {
    width: 37px;
    height: 37px;
    border-radius: 50px;
    padding: 8px 8px;
    background-color: $purple;
    font-size: 15px;
    margin-right: 14px;

    p {
      color: white;
      margin-left: 1px;
    }

  }
}

.global_popup_wrapper {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  display: grid;
}

@keyframes warning {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

$wrapper-h: 100vh;
$wrapper-w: calc(100vw - #{$sidenav-width});
.children-wrapper {
  padding: 50px 28px 28px;
  position: absolute;
  top: 0;
  left: $sidenav-width;
  min-height: $wrapper-h;
  width: $wrapper-w;
  background: #F9F9F9;

  .login__button {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }

  h1 {
    line-height: 32px;
    color: $main-background;
  }

  &__page-header {
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 28px !important;
    line-height: 32px !important;
    color: #0A1F44 !important;
    margin-bottom: 18px;
  }

  &__component-header {
    font-size: 24px;
  }

  &__small-component-header {
    font-size: 20px;
    color: $text-color-black;
  }

  .text-key {
    color: $text-color-black;
  }

}

.component-wrapper {
  display: flex;
  flex-direction: column;
  padding: 25px;
  background: #FFFFFF;
  border: 1px solid #EBECF0;
  box-sizing: border-box;
  border-radius: 15px;

}

@media (max-height: 1024px) {
  .sidenav {
    overflowy-: auto;

    &-exit {
      margin-top: 100px;
    }
  }
}


.global_popup {
  justify-self: center;
  background: #fff;
  position: relative;
  z-index: 1001;
  width: 475px;
  height: max-content;
  border-radius: 5px;
  margin-top: 58px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

.close_popup {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close_popup:hover path {
  fill: #2F80ED !important;
}

.global_popup .description_wrapper span,
.global_popup .wrapper_title_of_popup span {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000
}

.global_popup .wrapper_title_of_popup {
  padding: 0 20px;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ECECEC;
}

.global_popup .description_wrapper span {
  font-size: 14px;
  line-height: 20px;
  color: #818181
}

.global_popup .description_wrapper {
  padding: 22px 20px;
}

.global_popup .warning_wrapper {
  border: 1px solid #E2E4E9;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 17px 21px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0 11px;
  margin: 0 20px 26px;
}

.global_popup .warning_wrapper img {
  width: 32px;
  height: 32px;
  animation: imgAppear 1s forwards;
}

@keyframes imgAppear {
  from {opacity: 0}
  to {opacity: 1}
}

.global_popup .warning_wrapper span {
  align-self: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #FE3E3E;
}

.global_popup .wrapper_btn {
  display: grid;
  padding: 0 20px 24px;
  grid-template-columns: 150px auto;
  grid-gap: 0 15px;
}

.global_popup .wrapper_btn button {
  font-style: normal;
  width: 150px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #FFFFFF;
  border: none;
  background: #0CCC92;
}

.global_popup .wrapper_btn button:hover {
  background: #088072;
}

.global_popup .wrapper_btn button:last-child {
  background: #FE3E3E;
}

.sidenav_user_info {
  display: grid;
  padding: 16px 18px;
  grid-gap: 8px;
}

.sidenav_user_info span {
  font-family: Inter;
  font-style: normal;
}

.sidenav_user_info > div:first-child {
  display: grid;
}

.sidenav_user_info > div:first-child .user_info_company {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.sidenav_user_info > div:first-child .user_info_email {
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #BCBCBC;
}

.sidenav_user_info > div:last-child {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(2, max-content);
}

.sidenav_user_info > div:last-child .user_info_plan {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  background: #0CCC92;
  border-radius: 4px;
  padding: 1px 10px;
}

.sidenav_user_info > div:last-child button {
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #5C9BFF;
  background: none !important;
  border: none !important;
  padding: 0 !important;
}


.StripeElement{
  box-shadow: none !important;
  background: #FFFFFF !important;
  border: 1px solid #E2E4E9 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  transition: all .3s !important;
}

.StripeElement:hover {
  border: 1px solid #8ED6FF !important;
}

.StripeElement:hover.StripeElement--focus {
  border: 1px solid #5C9BFF !important;
}

.StripeElement--focus{
  border: 1px solid #5C9BFF !important;
}

.custom_tooltip_wrapper{
  position: absolute;
  width: 160px;
  height: max-content;
}


.custom_tooltip_content {
  width: 160px;
  height: max-content;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 8px 11px !important;
  position: absolute;
  bottom: 6px;
}

.custom_tooltip_triangle {
  position: absolute;
  bottom: 0;
  left: 12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5.5px 0 5.5px;
  border-color: rgba(0,0,0,.7) transparent transparent transparent;
}

.pagination{
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 15px;
  margin-top: 15px;
}


.pagination li a {
  padding: 7px 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #E2E4E9;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  color: #C4C4C4;
}

.no_licenses_wrapper div {
  display: grid;
  grid-gap: 12px;
}

.no_licenses_wrapper .no_licenses_header {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #4E5D78;
}

.no_licenses_wrapper .no_licenses_description {
  font-size: 14px;
  line-height: 20px;
  color: #818181;
}

.active_pagination a{
  background: #FFFFFF !important;
  border: 1px solid #2F80ED !important;
  color: #2F80ED !important;
}

.pagination li a:hover {
  background: #EAF6FD;
  color: #5C9BFF;
  border: 1px solid #8ED6FF;
}

.pagination li a:focus {
  background: #EAF6FD !important;
  border: 1px solid #5C9BFF !important;
  color: #5C9BFF!important;
}

.pagination li a:active {
  background: #EAF6FD !important;
  border: 1px solid #5C9BFF !important;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
  color: #5C9BFF !important;
}

.pagination .disabled a {
  background: #F9F9F9 !important;
  color: #C2C2C2 !important;
  border: 1px solid #E2E4E9 !important;
  box-shadow: none !important;

}

.pagination .disabled a:active {
  background: #F9F9F9 !important;
  color: #C2C2C2 !important;
  border: 1px solid #E2E4E9 !important;
  box-shadow: none !important;
}

.pagination .disabled a:focus {
  background: #F9F9F9 !important;
  color: #C2C2C2 !important;
  border: 1px solid #E2E4E9 !important;
  box-shadow: none !important;
}

.custom_tooltip_wrapper .tooltip_text{
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  word-break: break-all;
  color: white !important;
}
.custom_tooltip_wrapper .tooltip_text span{
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  word-break: break-all;
  color: white !important;
}
