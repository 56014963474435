@import "variables";
@import "global";

.login {
  display: flex;
  justify-content: center;
  background-color: #F9F9F9;
  height: 100vh;
  margin-top: -90px;

  .sign_up_text_big {
    font-size: 28px !important;
    line-height: 32px !important;
    color: #0A1F44 !important;
    font-weight: normal !important;
    margin-bottom: 13px;
  }

  .sign_up_text_bold {
    font-weight: normal !important;
    font-size: 17px !important;
    line-height: 20px !important;
    color: #0A1F44 !important;
    margin-bottom: 15px;
  }

  h1 {
    font-weight: 600;
    color: $text-color-black;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;
  }

  h2 {
    font-size: 28px !important;
    line-height: 32px !important;
    color: #0A1F44 !important;
    margin-bottom: 13px;
  }

  h3 {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #0A1F44 !important;
    margin-bottom: 15px;
  }

  a {
    color: #5C9BFF;
    font-size: 13px;
  }

  .grey_text_login {
    font-size: 13px;
    line-height: 16px;
    color: #818181;
    margin-bottom: 0;
  }

  &__logo {
    justify-self: start;
  }

  &__button {
    justify-self: end;
    color: $main;
    border: 2px solid $main;
    font-weight: 500;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 4px 16px;
    cursor: pointer;

    &:hover {
      background-color: $main;
      color: white;
    }
  }


  &__wrapper {
    width: 402px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 36px;
    height: max-content;
    margin-top: 110px;
  }


  &-form {
    display: flex;
    flex-direction: column;

    &__change {
      display: flex;
      align-items: center;
      margin-bottom: 27px;

      &__button {
        cursor: pointer;
        color: $main;
        margin-left: 13px;
        font-weight: 500;

        &:hover{
          color: $main-hover;
        }
        &--reset{
          margin-left: 0;
          margin-bottom: 16px;
        }
      }
    }

    &__label {
      margin-bottom: 4px;
    }

    &__input {
      margin-bottom: 16px;
      padding: 14px 15px 11px;
      border: 2px solid $gray;
      border-radius: 4px;

      &--error {
        border-color: $red!important;
      }

      &--match {
          color: $cyan!important;
      }

    }
    &--error {
      color: $red!important;
    }

    input:hover {
      border: 2px solid $main!important;
    }

    input:focus {
      border: 2px solid $main-hover!important;
    }

    &__checkbox {
      &--error {
        border: 2px solid $red!important;
      }


      label {
        display: grid;
        grid-template-columns: 32px 1fr;
        align-items: center;
        color: $text-color-black;
        position: relative;
        cursor: pointer;
        grid-gap: 16px;
      }

      input {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
      }

      span {
        justify-self: start;
        animation: bounceCategory 250ms!important;
      }

      label > span {
        margin-right: 16px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        border-radius: 3px;
        background: #fff;
        transition: all .3s;
        border: 2px solid $cyan;
      }


      &__checked {
          position: absolute;
          top: 4px;
          left: 4px;

      }
      &__not-checked {
        opacity: 0;
        position: absolute;
        background: $cyan;
        top: 1px;
        left: 1px;

      }
    }

    &__submit {
      margin-top: 8px;
      color: white;
      font-weight: 600!important;
      border: 2px solid $main;
      border-radius: 4px;
      background-color: $main;

      &:hover {
        background-color: $main-hover;
        border-color: $main-hover;
        //border: 2px solid $main;
      }
    }

    &__input, &__submit {
      padding-top: 12px;
      padding-bottom: 12px;
      font-weight: 500;
      border-radius: 4px;
    }
  }

  &__email{
    padding-top: 5.5px;
    padding-bottom: 5.5px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 14px;
    margin-bottom: 19px;
    color: $text-color-black;
    border: 1px solid #5C9BFF;
    background-color: #EAF6FD;
    text-align: center;
    font-size: 18px;
  }
  &__text {
    text-align: center;
    font-family: $font, serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: rgba($color: $color-black-dark, $alpha: 0.16);
  }

  &__link {
    color: rgba($color: $color-black-dark, $alpha: 0.16);
    text-decoration: underline;

    &:hover {
      color: rgba($color: $color-black-dark, $alpha: 0.16);
    }
  }
}

header {
  display: grid;
  padding: 28px 60px;
  grid-template-columns: 1fr 1fr;
}

footer {
  display: flex;
  left: 0;
  right: 0;
  padding: 29px;
  margin-top: 80px;
  border-top: solid 1px $gray-opacity;

  * {
    color: $gray;
  }

  a {
    margin-left: 34px;
    text-decoration: none;
  }

  p {
    position: absolute;
    right: 34px;
  }
}
p.login-form--error{
  margin-top: -8px;
  font-weight: 600 !important;
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 16px;
}

p.login-form__input--match{
  margin-top: -8px;
  font-weight: 600 !important;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 8px;
}

.password_warning {
  display: grid;
  grid-gap: 15px;
  padding: 6px 18px 11px;
  grid-template-columns: max-content 1fr;
  grid-auto-rows: max-content;
  border: 1px solid #E2E4E9;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10px;
}

.password_warning ul {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  padding-inline-start: 0;
  grid-gap: 0 14px;
  margin-bottom: 0;
  list-style: none;
}

.password_warning span,
.password_warning li{
  font-size: 12px;
  line-height: 16px;
  color: #818181;
}

.password_warning li span {
  position: relative;
  font-size: 11px;
}

.password_warning li::before {
  content: "• ";
  font-size: 120%;
  color: #C4C4C4;
}
