.input_component span{
    font-family: Inter !important;
    font-size: 13px !important;
    line-height: 20px !important;
    color: #4E5D78 !important;
    margin-bottom: 2px !important;
}

.input_search > div {
    position: relative;
}

.input_search .searchImage {
    background-image: url("../../assets/search_icon.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 13px;
    top: 9px;
}

.dropdown_component_wrapper {
    position: relative;
    cursor: pointer;
}

.dropdown_component_wrapper input{
    cursor: pointer;
}

.dropdown_component_wrapper .dropdown_image{
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.dropdown_component_wrapper .dropdown_component_opened {
    position: absolute;
    left: 0;
    top: 40px;
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #E2E4E9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    overflow-y: scroll;
    z-index: 999;
}

.dropdown_component_wrapper .dropdown_component_opened > span,
.dropdown_component_wrapper .dropdown_component_opened label{
    width: 100%;
    padding: 8px 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #4E5D78;
}

label {
    margin-bottom: unset;
}

.dropdown_component_wrapper .dropdown_component_opened span:hover,
.dropdown_component_wrapper .dropdown_component_opened div:hover{
    background: #EAF6FD;
}

.input_search:hover .searchImage{
    background-image: url("../../assets/search_active.svg");
}

.search_image_active {
    background-image: url("../../assets/search_active.svg") !important;
}

.input_component .custom_input,
.input_component textarea{
    padding: 7px 11px !important;
    width: 100%;
    height: 35px !important;
    background: #FFFFFF !important;
    border: 1px solid #E2E4E9 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-size: 15px !important;
    line-height: 21px !important;
    color: #333333 !important;
    font-family: Inter !important;
    resize: none;
}

.input_component textarea {
    height: auto !important;
}

.input_search .custom_input {
    padding: 6px 11px 6px 42px !important;
}

.input_component .custom_input:hover,
.input_component textarea:hover{
    border: 1px solid #8ED6FF !important;
}

.input_component .custom_input:focus,
.input_component textarea:focus{
    border: 1px solid #5C9BFF !important;
}

.input_component .custom_input:disabled,
.input_component .textarea:disabled{
    border: 1px solid #E2E4E9 !important;
    color: #C2C2C2 !important;
}

.error.custom_input{
    border: 1px solid #FE3E3E !important;
}

.error:hover.custom_input{
    border: 1px solid #FE3E3E !important;
}

.error:focus.custom_input {
    border: 1px solid #FE3E3E !important;
}

