.button_default {
    background: #5C9BFF !important;
    border-radius: 4px !important;
    width: 100%;
    height: 35px;
    color: white !important;
    font-family: Inter;
    font-style: normal !important;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: center !important;
    text-transform: uppercase;
    box-sizing: border-box !important;
    display: -webkit-box !important;
    -webkit-box-pack: center !important; /* justify-content */
    -webkit-box-align: center !important; /* align-items */
    -webkit-box-align: center !important;
    align-items: center !important;
    -webkit-flex-align: center !important;
    -ms-flex-align: center !important;
    -webkit-align-items: center !important;
    padding: unset !important;
    border: unset !important;
}

.button_default img {
    margin-right: 8px;
    margin-bottom: 2px;
}

.main_button:focus {
    border: 1px solid #2F80ED !important;
}

.main_button:hover {
    background: #2F80ED !important;
}

.main_button:active {
    background: #5C9BFF !important;
    border: none !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.main_button:disabled {
    background: rgba(92, 155, 255, 0.3) !important;
    color: rgba(47, 128, 237, 0.5) !important;
}

.button_accent {
    background: #0CCC92 !important;
}

.button_accent:focus {
    background: #0CCC92 !important;
    border: 1px solid #088072 !important;}

.button_accent:hover {
    background: #07BE87 !important;
}

.button_accent:active {
    background: #0CCC92 !important;
    border: none !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.button_accent:disabled {
    background: #B7DDD2 !important;
    color: #3EBDC0 !important;
}

.warning_button {
    background: #FFAB00 !important;
}

.warning_button:focus {
    background: #FFAB00 !important;
    border: 1px solid #D99100 !important;}

.warning_button:hover {
    background: #D99100 !important;
}

.warning_button:active {
    border: none !important;
    background: #FFAB00 !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.warning_button:disabled {
    background: #F8DFAD !important;
    color: #C7AF7F !important;
}


.danger_button {
    background: #FE3E3E !important;
}

.danger_button:focus {
    background: #FE3E3E !important;
    border: 1px solid #CC0505 !important;}

.danger_button:hover {
    background: #CC0505 !important;
}

.danger_button:active {
    background: #FE3E3E !important;
    border: none !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.danger_button:disabled {
    background: #FFC5C5 !important;
    color: #DC7F7F !important;
}

.cancel_button {
    background: white !important;
    border: 1px solid #5C9BFF !important;
    color: #5C9BFF !important;
}

.cancel_button:focus {
    color: #2F80ED;
    background: #EAF6FD !important;
    border: 1px solid #2F80ED !important;}

.cancel_button:hover {
    background: #EAF6FD !important;
}

.cancel_button:active {
    border: none !important;
    background: white !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.cancel_button:disabled {
    background: white !important;
    border: 1px solid #5C9BFF !important;
    color: #5C9BFF !important;
}

.download_button {
    background: #FFFFFF !important;
    color: #5C9BFF !important;
}

.download_button:focus {
    background: #EAF6FD !important;
    border: 1px solid #8ED6FF !important;
    color: #2F80ED !important;
}

.download_button:hover {
    background: #EAF6FD !important;
}

.download_button:active {
    border: none !important;
    background: #FFFFFF !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.download_button:disabled {
    background: white !important;
    color: #BCBCBC !important;
}


.delete_button {
    background: #FFFFFF !important;
    color: #FE3E3E !important;
}

.delete_button:focus {
    background: rgba(254, 62, 62, 0.1) !important;
    border: 1px solid #FE3E3E !important;
}

.delete_button:hover {
    background: rgba(254, 62, 62, 0.1) !important;
}

.delete_button:active {
    background: rgba(254, 62, 62, 0.1) !important;
    border: none !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.delete_button:disabled {
    background: white !important;
    color: #BCBCBC !important;
}

.add_button {
    background: #0CCC92 !important;
}

.add_button:focus {
    background: #0CCC92 !important;
    border: 1px solid #088072 !important;
}

.add_button:hover {
    background: #34B08B !important;
}

.add_button:active {
    background: #0CCC92 !important;
    border: none !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.add_button:disabled {
    border: 1px solid #088072 !important;
}

.big_button {
    height: 40px !important;
}
