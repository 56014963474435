.pricing_content {
    display: grid;
    padding: 0 38px;
    align-content: center;
    grid-gap: 24px;
    margin: auto;
    justify-content: center;
}

.pricing_top {
    display: grid;
    grid-template-columns: 1fr auto;
}


.top_buttons {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 12px;
}

.per_month_text {
    font-size: 14px;
    line-height: 20px;
    color: #818181;
    margin-top: auto;
    margin-bottom: 6px;
    margin-left: 5px;
}

.top_buttons .billing_type {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8A94A6;
    align-self: center;
}

.top_buttons button {
    border: 1px solid #5C9BFF;
    box-sizing: border-box;
    border-radius: 4px;
    background: transparent;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #5C9BFF;
    padding: 4px 12px !important;
}

.top_buttons button:hover {
    border: 1px solid #2F80ED;
    background: rgba(47,128,237, .3);
}

.top_buttons .active {
    background: #5C9BFF;
    border-radius: 4px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.top_buttons .active:hover {
    background: #2F80ED;
}

.pricing_body {
    display: grid;
    grid-template-columns: repeat(4, 286px);
    grid-gap: 20px;
}

.pricing_col {
    padding: 26px 20px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.plan_name_wrapper {
    border-bottom: solid 1px rgba(0,0,0, .1);
    padding-bottom: 18px;
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 3px;
}

.plan_name {
    font-size: 26px;
    line-height: 23px;
    color: #0A1F44;
}

.active_plan {
    padding: 1px 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    background: #0CCC92;
    border-radius: 4px;
    color: #FFFFFF;
}

.pricing_head_info {
    display: grid;
    grid-template-columns: 184px 1fr;
}


.pricing_head_info span:first-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8A94A6;
}

.pricing_head_info span:last-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #6A7280;
}

.pricing_head {
    display: grid;
    padding-bottom: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    grid-gap: 10px;
    position: relative;
}

.pricing_features {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 13px;
    padding: 22px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.pricing_features .feature {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 12px;
}

.pricing_features .feature span {
    font-size: 14px;
    line-height: 20px;
    color: #8A94A6;
}

.pricing_footer {
    display: grid;
    grid-gap: 24px;
    padding-top: 26px;
}

.pricing_price {
    display: flex;
    grid-gap: 40px;
    padding-bottom: 14px;
    margin-bottom: 4px;
    border-bottom: 1px solid rgba(0,0,0, .1);
}

.pricing_price  .pricing_col_last {
    margin-left: auto;
}

.active_plan_footer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 6px;
}

.active_plan_footer > div {
    display: grid;
    grid-template-columns: 45px 1fr;
}

.active_plan_footer > div span:first-child {
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 16px !important;
    color: #818181 !important;
}

.active_plan_footer > div span:last-child {
    font-size: 13px;
    line-height: 16px;
    color: #4E5D78;
}

.request_price {
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    text-align: right;
    color: #0CCC92;
    margin-top: 12px;
}

.pricing_annual_info {
    display: grid;
    grid-gap: 10px;
}

.pricing_annual_info span {
    font-size: 12px;
    line-height: 15px;
    color: #818181;
}

.price_col {
    display: flex;
    flex-direction: column;
}

.price_head {
    font-size: 14px;
    line-height: 20px;
    color: #8A94A6;
    word-break: keep-all;
}

.price {
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    color: #0CCC92;
    margin-top: auto;
    display: flex;
}

button {
    border-radius: 4px;
    background: #5C9BFF;
    color: white;
    padding: 12px 0;
    border: none;
}

button:hover {
    background: #2F80ED;
}

button.active {
    background: #0CCC92;
}

button.active:hover {
    background: #088072;
}

.pricing_body button {
    margin-top: auto;
}

