.input_component p{
    font-family: Inter !important;
    font-size: 13px;
    line-height: 20px;
    color: #4E5D78 !important;
    margin-bottom: 2px !important;
}

.login-form__checkbox label > span {
    border: 1px solid #E2E4E9;
    box-sizing: border-box;
    border-radius: 3px;
}

.checkbox {
    position: absolute !important;
    z-index: -1 !important;
    opacity: 0 !important;
}

.login-form__checkbox__checked {
    height: 18px;
    top: 2px !important;
    left: 2px !important;
}

.login-form__checkbox__not-checked {
    top: 2px !important;
    left: 2px !important;
}

.login-form__checkbox label:hover > span {
    border: 1px solid #8ED6FF;
}

.login-form__checkbox label > span:focus {
    border: 1px solid #5C9BFF;
}

.checkbox:focus +  .custom_checkbox_label > span {
    border: 1px solid #5C9BFF;
}
