.order_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #F9F9F9;
    z-index: 1000000;
    justify-content: unset;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 140px;
    align-items: center;
}

label {
    width: 100% !important;
}

.order_wrapper .order_content > span {
    grid-column: 1/-1;
    font-size: 28px;
    line-height: 32px;
    color: #0A1F44;
    margin: 27px auto 0 0;
}

input {
    background: #FFFFFF;
    border: 1px solid #E2E4E9;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
}

.payment_details {
    background: #FFFFFF;
    border: 1px solid #EBECF0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 24px;
    display: grid;
    grid-gap: 12px;
    height: max-content;
}

.purchase_summary {
    background: #FFFFFF;
    border: 1px solid #EBECF0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 24px;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.payment_details div span {
    font-size: 13px;
    line-height: 20px;
    color: #4E5D78;
}

.payment_details div {
    display: flex;
    flex-direction: column;
}

.StripeElement {
    border: 1px solid #E2E4E9 !important;
}

.payment_details > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4E5D78;
}

.order_content{
    display: grid;
    grid-template-columns: 540px 300px;
    grid-gap: 16px 40px;
    padding-bottom: 60px;
}

.order_wrapper{
    margin: auto;
}

.login-form__checkbox label > span {
    height: 24px !important;
    width: 24px !important;
}

.login-form__checkbox label {
    grid-gap: 0;
}

.login-form__checkbox__checked{
    height: 18px;
    top: 1px;
    left: 1px;
}

.payment_footer button{
    border-radius: 4px !important;
    margin: 0 !important;
}

.payment_footer {
    display: grid !important;
    grid-gap: 64px;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.summary_title span {
    font-size: 22px;
    line-height: 23px;
    color: #0A1F44;
}

.summary_title {
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.summary_content {
    display: grid;
    grid-gap: 12px;
}

.summary_content div,
.summary_total{
    display: grid;
    grid-template-columns: 1fr auto;
}

.summary_content div span:first-child,
.summary_total span:first-child{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8A94A6;
}

.summary_content div span:last-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #6A7280;
}


.summary_price {
    font-size: 15px !important;
    color: #0CCC92 !important;
}

.summary_total{
    padding: 18px 0;
    border-bottom: 1px solid rgba(0,0,0,.1);
    border-top: 1px solid rgba(0,0,0,.1);
}

.summary_description {
    margin-top: 16px;
}

.summary_description span {
    font-size: 13px;
    line-height: 20px;
    color: #818181;
}

.order_content .payment_details  .payment_details_header {
    margin: -24px -24px 0;
    padding: 12px 16px;
    border-bottom: 1px solid rgba(0,0,0, .1);
}

.order_content .payment_details  .payment_details_header span{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4E5D78;
}

.order_content .payment_details  .billing_details_wrapper .billing_details_content{
    padding: 0 !important;
}

.order_content .payment_details .billing_details_wrapper > div:first-child {
    padding: 12px 24px !important;
    margin: 0 -24px 12px;
    border-bottom: 1px solid rgba(0,0,0, .1) !important;
}

.order_content .payment_details  .billing_details_wrapper {
    border: none !important;
    margin-bottom: 8px !important;
}

.billing_details_wrapper .billing_details_content input, .billing_details_wrapper .billing_details_content textarea,
.billing_details_content .billing_double_input_big,
.billing_details_content .billing_double_input{
    width: 100% !important;
}

