.all_data {
    height: 56px;
    width: 4px;
    background: #EFEFEF;
    border-radius: 2px;
    position: relative;
    margin-top: auto;
}

.used_data {
    position: absolute;
    border-radius: 2px;
    bottom: 0;
    left: 0;
    width: 4px;
}

.used_data_component {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
}

.used_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    color: #818181;
    margin-top: auto;
}
