.input_component_radio p {
    font-family: Inter !important;
    font-size: 13px !important;
    line-height: 20px !important;
    color: #4E5D78 !important;
    margin-bottom: 2px !important;
}

.login-form__checkbox_radio label > span {
    border: 1px solid #E2E4E9;
    box-sizing: border-box;
    border-radius: 3px;
}

.checkbox_radio {
    position: absolute !important;
    z-index: -1 !important;
    opacity: 0 !important;
}

.input_component_radio input {
    height: 0;
    width: 0;
}

.login-form__checkbox__checked_radio {
    position: absolute;
    background: #0CCC92;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top: 2px !important;
    left: 26px !important;
}

.login-form__checkbox__not-checked_radio {
    top: 2px !important;
    left: 2px !important;
}

.login-form__checkbox_radio label:hover > span {
    border: 1px solid #8ED6FF;
}

.login-form__checkbox_radio label > span:focus {
    border: 1px solid #5C9BFF;
}

.checkbox_radio:focus + .custom_checkbox_label_radio > span {
    border: 1px solid #5C9BFF;
}

.login-form__checkbox_radio label > span {
    height: 24px !important;
    width: 48px !important;
    background: #FFFFFF;
    border: 1px solid #E2E4E9;
    box-sizing: border-box;
    border-radius: 12px !important;
}

.login-form__checkbox_radio label > span {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    position: relative;
    background: #fff;
    transition: all .3s;
}

.input_component_radio span {
    font-family: Inter !important;
    font-size: 13px !important;
    line-height: 20px !important;
    color: #4E5D78 !important;
    margin-bottom: 2px !important;
}

.login-form__checkbox_radio span {
    justify-self: start;
    animation: bounceCategory 250ms !important;
}

.login-form__checkbox_radio label {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    color: #333333;
    position: relative;
    cursor: pointer;
    grid-gap: 0;
}

.login-form__checkbox__not-checked_radio {
    position: absolute;
    background: #E2E4E9;;
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
}
