.license_status {
    border-radius: 4px !important;
    width: max-content;
    height: max-content;
}

.license_status span {
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    color: #FFFFFF !important;
    padding: 3px 12px 4px !important;
}

.active_license {
    background: #0CCC92 !important;
}

.pending_license {
    background: #FFC107 !important;
}

.inactive_license {
    background: #FE3E3E !important;
}
