.billing_details_wrapper {
    background: #FFFFFF;
    border: 1px solid #EBECF0;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 28px;
}

button {
    padding: 8px 8px !important;
}

.billing_default_input {
    width: 540px;
}

.billing_details_wrapper > div:first-child {
    padding: 12px 16px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4E5D78;
}

.payment_details > .no_details,
.no_invoices{
    padding: 12px 16px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #818181;
}

.payment_details_info {
    padding: 12px 16px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
}

.payment_details_info span,
.payment_details_info b{
    font-size: 13px;
    line-height: 16px;
    color: #4E5D78;
}

.payment_details_info span:first-child {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #4E5D78;
}

.payment_details button,
.subscription_wrapper button{
    background: #5C9BFF;
    border-radius: 4px;
    border: 2px #5C9BFF solid;
}

.billing_details_wrapper .billing_details_content {
    padding: 25px 19px;
    display: grid;
}

.billing_details_wrapper .billing_details_content input,
.billing_details_wrapper .billing_details_content textarea{
    width: 540px;
    background: #FFFFFF;
    border: 1px solid #E2E4E9;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 10px;
    resize: none;
}

.billing_details_wrapper .billing_details_content .billing_input_info {
    display: grid;
    grid-template-columns: 540px auto;
    grid-gap: 14px;
}

.billing_input_info span {
    font-size: 12px;
    line-height: 17px;
    color: #818181;
}

.billing_details_content div > span {
    font-size: 13px;
    line-height: 20px;
    color: #4E5D78;
}

.billing_details_content > div{
    display: flex;
    flex-direction: column;
}

.billing_details_content .billing_double_input_big{
    grid-template-columns: 2fr 1fr;
    display: grid;
    grid-gap: 14px;
    width: 540px;
}

.billing_details_content .billing_double_input_big input {
    width: auto !important;
}

.billing_details_content .billing_double_input_big div{
    display: flex;
    flex-direction: column;
}

.billing_details_content .billing_double_input {
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-gap: 14px;
    width: 540px;
}

.billing_details_content .billing_double_input input {
    width: auto !important;
}

.billing_details_content .billing_double_input div{
    display: flex;
    flex-direction: column;
}

.subscriptions div{
    border-bottom: 1px solid rgba(0,0,0,.1);
    display: grid;
    grid-template-columns: 200px 1fr;
    padding: 12px 16px;
}

.subscriptions div span:first-child {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #4E5D78;
}

.subscriptions div span:last-child {
    font-size: 13px;
    line-height: 16px;
    color: #4E5D78;
}

.subscription_wrapper button {
    margin: 25px 16px ;
}



.invoices div{
    border-bottom: 1px solid rgba(0,0,0,.1);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 12px 16px;
}

.invoices div span:first-child {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #4E5D78;
}

.invoices div span:nth-child(3) {
    margin-left: 20px;
}

.invoices .invoice_plan_cost {
    font-size: 13px;
    line-height: 16px;
    color: #4E5D78;
}

.update_payment_content {
    padding: 14px 18px 25px;
}
