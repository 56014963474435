$main: #5C9BFF;
$main-hover: #2F80ED;
$main-background: #0A1F44;
$cyan: #0CCC92;
$purple: #5E63C9;
$gray: #C4C4C4;
$red: #FE3E3E;
$gray-opacity: rgba(196, 196, 196, 0.5);
$color-black-dark: #060606;
$text-color-black: #333333;

$font: 'Inter', sans-serif;

$transition: 400ms;

$sidenav-width: 224px;
$header-height: 60px;
