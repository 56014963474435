.notification {
    display: grid;
    box-shadow: 1px 1px 17px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    padding: 8px 12px;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    margin-left: 24px;
    animation: 4s warning forwards;
    z-index: 100001;
}

.status {
    padding-right: 8px;
    border-right: rgba(53, 45, 45, 0.07) 1px solid;
}

.notification span {
    padding-left: 8px;
    font-size: 15px;
    line-height: 17px;
    color: #FFFFFF;
    margin-right: 100px;
}

.success {
    background: #68C38A;
}

.warning {
    background: #FFC107;
}

.error {
    background: #FE3E3E;
}
